import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

const Consulta = () => {

  useEffect(() => {
    document.title = 'Escolaridad | ASIJEMIN';
  }, []);

  const [dni, setDni] = useState('');
  const [result, setResult] = useState(null);
  const [formVisible, setFormVisible] = useState(true);
  const [isButtonVisible, setIsButtonVisible] = useState(true);


 



  const handleChange = (e) => {
    setDni(e.target.value);
  };

  const camposFiltrados = [
    "Esposa/o",
    "Madre",
    "Padre",
    "Concubino",
    "Hermano/a",
  ];

  const message = 'Presentar certificado de escolaridad';

  // const handleSubmit = async (e) => {
    
  //   e.preventDefault();
  
  //   const dniRegex = /^\d{8,}$/;
  //   if (!dni.match(dniRegex)) {
  //     Swal.fire({
  //       title: 'Error!',
  //       text: 'El DNI debe contener al menos 8 dígitos numéricos.',
  //       icon: 'error',
  //     });
  //     return;
  //   }

  //   // Verificar si el DNI ya existe en la base de datos
  // const verificationMessage = await checkDni(dni);
  // if (verificationMessage === 'El DNI ya está en la base de datos, ya inició el trámite.') {
  //   Swal.fire({
  //     title: 'Error!',
  //     text: verificationMessage,
  //     icon: 'error',
  //   });
  //   return;
  // }

  //   // Mostrar el estado de los datos antes de la solicitud
  //   console.log("Datos a enviar:", result);
  
  //   // Aquí puedes agregar cualquier otro dato que quieras enviar
  //   const otherData = "yourOtherData";
  
  //   // Realiza la solicitud a la API PHP
  //   fetch('https://am.asijemin.org.ar/test/dni.php', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //     body: `dni=${dni}&otherData=${otherData}`,
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(`Error al hacer la solicitud: ${response.statusText}`);
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setResult({
  //         afiliado: data.na1.id_afiliado,
  //         nombre: data.na1.afiliadonombre,
  //         dni: dni,
  //         aporte: data.na1.aporte,
  //         na1: { nombre: data.na1.nombre, fn: data.na1.fn, edad: data.na1.edad, p: data.na1.p },
  //         na2: { nombre: data.na2.nombre, fn: data.na2.fn, edad: data.na2.edad, p: data.na2.p },
  //         na3: { nombre: data.na3.nombre, fn: data.na3.fn, edad: data.na3.edad, p: data.na3.p },
  //         na4: { nombre: data.na4.nombre, fn: data.na4.fn, edad: data.na4.edad, p: data.na4.p },
  //         na5: { nombre: data.na5.nombre, fn: data.na5.fn, edad: data.na5.edad, p: data.na5.p },
  //         na6: { nombre: data.na6.nombre, fn: data.na6.fn, edad: data.na6.edad, p: data.na6.p },
  //         na7: { nombre: data.na7.nombre, fn: data.na7.fn, edad: data.na7.edad, p: data.na7.p },
          
  //       });
      
  
  //       // Ocultar el formulario después de recibir los resultados
  //       setFormVisible(false);
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //       Swal.fire({
  //         title: 'Error!',
  //         text: 'Error al procesar la solicitud',
  //         icon: 'error',
  //       });
  //       setResult(null);
  //     });
  // };


  async function checkDni(dni) {
    try {
      const response = await fetch(`https://asijeminapis.website:4548/api/escolaridad/verificar/${dni}`);
      if (!response.ok) {
        throw new Error(`Error al hacer la solicitud: ${response.statusText}`);
      }
      const data = await response.text();  // Asume que la respuesta es un texto
      return data;
    } catch (error) {
      console.error('Error:', error);
      return 'Error al verificar el DNI';  // Devuelve un mensaje de error
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const dniRegex = /^\d{8,}$/;
    if (!dni.match(dniRegex)) {
      Swal.fire({
        title: 'Error!',
        text: 'El DNI debe contener al menos 8 dígitos numéricos.',
        icon: 'error',
      });
      return;
    }
  
    // Verificar si el DNI ya existe en la base de datos
    const verificationMessage = await checkDni(dni);
  if (verificationMessage === 'El DNI ya está en la base de datos, ya inició el trámite.') {
    Swal.fire({
      title: 'Error!',
      text: verificationMessage,
      icon: 'error',
    });
    setIsButtonVisible(false);  // Oculta el botón
    return;
  }
  
    // Mostrar el estado de los datos antes de la solicitud
    console.log("Datos a enviar:", result);
  
    // Aquí puedes agregar cualquier otro dato que quieras enviar
    const otherData = "yourOtherData";
  
    // Realiza la solicitud a la API PHP
    fetch('https://am.asijemin.org.ar/test/dni.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `dni=${dni}&otherData=${otherData}`,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error al hacer la solicitud: ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => {
        setResult({
          afiliado: data.na1.id_afiliado,
          nombre: data.na1.afiliadonombre,
          dni: dni,
          aporte: data.na1.aporte,
          na1: { nombre: data.na1.nombre, fn: data.na1.fn, edad: data.na1.edad, p: data.na1.p },
          na2: { nombre: data.na2.nombre, fn: data.na2.fn, edad: data.na2.edad, p: data.na2.p },
          na3: { nombre: data.na3.nombre, fn: data.na3.fn, edad: data.na3.edad, p: data.na3.p },
          na4: { nombre: data.na4.nombre, fn: data.na4.fn, edad: data.na4.edad, p: data.na4.p },
          na5: { nombre: data.na5.nombre, fn: data.na5.fn, edad: data.na5.edad, p: data.na5.p },
          na6: { nombre: data.na6.nombre, fn: data.na6.fn, edad: data.na6.edad, p: data.na6.p },
          na7: { nombre: data.na7.nombre, fn: data.na7.fn, edad: data.na7.edad, p: data.na7.p },
        });
  
        // Ocultar el formulario después de recibir los resultados
        setFormVisible(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        Swal.fire({
          title: 'Error!',
          text: 'Error al procesar la solicitud',
          icon: 'error',
        });
        setResult(null);
      });
  };






  const handleRequest = () => {

    
    // Verificar si el aporte es 0
    if (result.aporte === 0) {
      Swal.fire({
        title: 'Error!',
        text: 'Usted no es un afiliado cotizante',
        icon: 'error',
      });
  
      // Restablecer el estado a su estado inicial
      setDni('');
      setResult(null);
      setFormVisible(true);
  
      return; // Detener la ejecución de la función
    }
  
    // Mostrar el estado de los datos antes de la solicitud
    console.log("Datos a enviar:", result);
  
    // Realiza la solicitud a tu endpoint
    fetch('https://asijeminapis.website:4548/api/escolaridad', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(result),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error al hacer la solicitud: ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => {
        Swal.fire({
          title: '¡Éxito!',
          text: 'Solicitud de escolaridad enviada correctamente',
          icon: 'success',
        });
  
        // Restablecer el estado a su estado inicial
        setDni('');
        setResult(null);
        setFormVisible(true);
      })
      .catch((error) => {
        console.error('Error:', error);
        Swal.fire({
          title: 'Error!',
          text: 'Error al enviar la solicitud de escolaridad',
          icon: 'error',
        });
  
        // Restablecer el estado a su estado inicial
        setDni('');
        setResult(null);
        setFormVisible(true);
      });
  };

  return (
    <div>
      {formVisible && (
        <form onSubmit={handleSubmit} className="mt-8 ml-8 text-center formul">
          <h3>Formulario de Escolaridad</h3>
          <p className="font-[Roboto] text-xl mb-4">
            Ingrese el DNI del afiliado
          </p>

          <div className="col-md-6 d-grid mx-auto">
            <input
              className="form-control mb-4"
              type="text"
              value={dni}
              onChange={handleChange}
            />

            <button type="submit" className="btn btn-primary">
              Consultar
            </button>
          </div>
        </form>
      )}

      {result && (
        <div className="mt-4">
          <h3>Resultados:</h3>
          <p>
            Nombre del Afiliado:{" "}
            <strong className="na">{result.nombre}</strong>{" "}
          </p>
          <p>
            Número de Afiliado:{" "}
            <strong className="nafil">{result.afiliado}</strong>{" "}
          </p>

          <table className="table table-hover">
            <thead>
              <tr className="cabecera">
                <th>Nombre</th>
                <th>Fecha de Nacimiento</th>
                <th>Vínculo</th>
                <th>Edad</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(result).map((key, index) => {
                const item = result[key];
                const nombre = item && item.nombre;
                const fechaNacimiento = item && item.fn;
                const parentesco = item && item.p;
                const edad = item && item.edad;

                const mostrarEdad = edad && (
                  <>
                    {edad} {edad > 20 && <span>- {message}</span>}
                  </>
                );

                if (
                  nombre &&
                  nombre.trim() !== "" &&
                  !camposFiltrados.includes(parentesco)
                ) {
                  return (
                    <tr key={index}>
                      <td className="nombre_hijo">{nombre}</td>
                      <td className="fn">{fechaNacimiento}</td>
                      <td className="vinculo">{parentesco}</td>
                      <td className="edad">{mostrarEdad}</td>
                    </tr>
                  );
                }

                return null;
              })}
            </tbody>
          </table>

          {isButtonVisible && (
      <button onClick={handleRequest} className="btn btn-primary">
        Solicitar Escolaridad
      </button>
    )}
        </div>
      )}
    </div>
  );
};

export default Consulta;

// App.js
import './App.css';
import Consulta from './components/Consulta/Consulta';

function App() {
    return (
      <>
        <div className="container-fluid">
          <div className="row contenedorgral" >
            <div className="col-12 col-md-6 col-sm-4 lateral" >
            </div>
            <div className="col-12 col-md-6 col-sm-8">
              <Consulta/>
            </div>
          </div>
        </div>
      </>
    );
}

export default App;